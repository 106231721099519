<template>
    <section>
        <section class="search-container">
            <a-form layout="inline" class="m-t-14">
                <a-form-item label="里程碑名称">
                    <a-input class="search-input" placeholder="请输入" v-model="searchInfo.milestoneName" />
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" class="m-r-8" @click="search"><a-icon type="search" />查询</a-button>
                    <a-button class="m-r-8" @click="reset"><a-icon type="reload" />重置</a-button>
                    <a-button type="primary" @click="showDrawerModal(null)" v-if="listMap.length === 0">添加里程碑</a-button>
                </a-form-item>
            </a-form>
        </section>

        <a-row type="flex" :gutter="20" class="list-card-container" v-if="listMap.length">
            <a-col :span="6" v-for="(item,key) in listMap" :key="key" class="m-t-16 list-item-wrap">
                <a-row class="list-card-bg">
                    <a-row class="list-wrap">
                        <a-col>
                            <a-row class="list-item">
                                {{item.milestoneName}}
                            </a-row>
                            <a-row class="list-item">
                                {{item.createTime}}
                            </a-row>
                            <a-row class="list-item" style="padding-bottom:44px">
                                总计划时间：{{item.startDate}} - {{item.endDate}}
                            </a-row>
                            <!-- <a-row class="list-item" style="padding-bottom:24px">
                                实际进度：{{item.actualDay || 0}}
                            </a-row> -->
                            <a-row class="list-btn-group-position">
                                <router-link :to="{path:'/pims/projectPlan/milepost/progress',query:{id:item.id}}">
                                    <a-button size="small">进度管理</a-button>
                                </router-link>
                                <a-dropdown>
                                    <a-button size="small">更多
                                        <a-icon type="caret-down" /></a-button>
                                    <a-menu slot="overlay">
                                        <a-menu-item key="1">
                                            <router-link :to="{path:'/pims/projectPlan/milepost/config',query:{id:item.id}}">里程碑配置</router-link>
                                        </a-menu-item>
                                        <a-menu-item key="2">
                                            <a-popconfirm title="确定删除？" @confirm="deleteItem(item.id)">删除</a-popconfirm>
                                        </a-menu-item>
                                    </a-menu>
                                </a-dropdown>
                            </a-row>
                        </a-col>
                    </a-row>
                    <!-- <a-tag class="list-status">{{item.dutyState === 1 ? '进行中' : item.dutyState === 2 ? '已结束' : '未开始'}}</a-tag> -->
                </a-row>
            </a-col>
        </a-row>
        <empty v-else :height="430" />
        <section class="pagination-container">
            <a-pagination size="default" :current="pageNum" :total="total" hide-on-single-page @change="changePageNum" />
        </section>

        <!-- modal -->
        <a-drawer
        class="drawer_module"
        :title="drawerModal.title"
        placement="right"
        :visible="drawerModal.visible"
        :width="350"
        :destroyOnClose="true"
        @close="drawerModal.visible = false"
        >
        
        <a-form class="form-container" :form="form" >
            <section class="form-item">
                <a-form-item label="里程碑名称">
                    <a-input placeholder="请输入" v-decorator="['milestoneName',{rules:[{required:true,message:'请输入里程牌名称'}]}]" />
                </a-form-item>
                <a-row class="form-btn-group">
                        <a-button @click="submit">提交</a-button>
                        <a-button @click="drawerModal.visible = false">取消</a-button>
                    </a-row>
            </section>
        </a-form>
    </a-drawer>
    </section>
</template>

<script>
import empty from '@/components/empty'
export default {
    data(){
        return{
            searchInfo:{},
            pageNum:1,
            total:0,
            listMap:[],
            drawerModal:{
                visible:false,
                title:'添加里程碑'
            }
        }
    },
    components:{empty},
    beforeCreate(){
        this.form = this.$form.createForm(this)
    },
    created(){
        this.queryMilestOneList()
    },
    methods:{
        queryMilestOneList(){
            let params = {...this.searchInfo}
            params.current = this.pageNum
            this.$api.queryMilestOneList(params).then(res =>{
                if(res.code === 200){
                    this.listMap = (res.data && res.data.records) || []
                    this.total = (res.data && res.data.total) || 0

                }
            })
        },
        reset(){
            this.searchInfo = {}
            this.pageNum = 1
            this.queryMilestOneList()
        },
        search(){
            this.pageNum = 1
            this.queryMilestOneList()
        },
        changePageNum(p){
            this.pageNum = p
            this.queryMilestOneList()
        },
        showDrawerModal(){
            this.drawerModal.visible = true
        },
        submit(){
            this.form.validateFields((err,formData) =>{
                if(!err){
                    if(this.mid){
                        formData.id = this.mid
                    }
                    let apiName = this.mid ? 'updateMilestOne' : 'insertMilestOne'
                        this.$api[apiName](formData).then(res =>{
                            if(res.code === 200){
                                this.drawerModal.visible = false
                                this.queryMilestOneList()
                            }
                        })
                }
            })
        },
        deleteItem(id){
            this.$api.deleteMilestOne(id).then(res =>{
                if(res.code === 200){
                    this.queryMilestOneList()
                }
            })
        }
    }
}
</script>